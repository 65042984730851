import { createApp } from "vue";
import App from "./App.vue";
import { setup as setupFirebase } from "@/services/firebase.service";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "@/quasar-user-options";
import VueGoogleMaps from "@fawmi/vue-google-maps";

setupFirebase();

const app = createApp(App);

app
  .use(router)
  .use(Quasar, quasarUserOptions)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAgZ9M3NovkWaXdbBubAHSqGEFgVTlja78",
    },
  });

app.mount("#app");
