import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyDzf_98DedZf4GmCC_87SkgsLCnHuhnJEg",
  authDomain: "polebatics.firebaseapp.com",
  projectId: "polebatics",
  storageBucket: "polebatics.appspot.com",
  messagingSenderId: "520981295202",
  appId: "1:520981295202:web:559505367f2a314bdae13b",
  measurementId: "G-7Q7LEKRW1M",
};
export const setup = () => {
  initializeApp(firebaseConfig);
};

export const firebaseApp = initializeApp(firebaseConfig);
