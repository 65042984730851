export enum TimeSlot {
  // FIRST = "12:00 - 13:00",
  // SECOND = "13:00 - 14:00",
  // THIRD = "14:00 - 15:00",
  // FOURTH = "15:00 - 16:00",
  // FIFTH = "16:00 - 17:00",
  SIXTH = "17:00 - 18:00",
  SEVENTH = "18:00 - 19:00",
  EIGHTH = "19:00 - 20:00",
  NINTH = "20:00 - 21:00",
  TENTH = "21:00 - 22:00",
}
