import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { userService } from "@/services/user.service";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/kurse",
    name: "kurse",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KurseView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminPageView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/probetraining",
    name: "probetraining",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AnmeldungView.vue"),
    props: (route) => ({ kursName: route.query.kursName }),
  },
  {
    path: "/kurs",
    name: "OneKursTyp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OneKursTypView.vue"),
    props: (route) => ({
      kursType: route.query.kursType,
    }),
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KontaktView.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ImpressumView.vue"),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DatenschutzView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth))
    if (await userService.isLoggindAsAdmin()) {
      next();
    } else {
      next({ name: "login" });
    }
  else next();
});

export default router;
