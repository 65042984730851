import { User } from "@firebase/auth";

import {
  collection,
  Firestore,
  getDocs,
  query,
  where,
  addDoc,
  getFirestore,
} from "firebase/firestore";
import { MyUser } from "@/models/MyUser";
import { firebaseApp } from "@/services/firebase.service";

const db: Firestore = getFirestore(firebaseApp);

export const userService = {
  async getUser(uid: string): Promise<MyUser | undefined> {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return undefined;
    } else {
      let foundUser: MyUser | undefined;
      querySnapshot.forEach((doc) => {
        foundUser = doc.data() as MyUser;
      });
      return foundUser;
    }
  },

  async isLoggindAsAdmin(): Promise<boolean> {
    const user = localStorage.getItem("user");
    if (user) {
      const fireBaseUser = await this.getUser(JSON.parse(user).uid);
      return !!fireBaseUser;
    } else {
      return false;
    }
  },

  async saveUserToFirebase(user: User) {
    const myUser = this.mapUser(user);
    const addData = async () => {
      try {
        await addDoc(collection(db, "users"), myUser);
        return myUser;
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    };
    try {
      const usersCollection = collection(db, "users");
      const q = query(usersCollection, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return await addData();
      } else {
        let foundUser: MyUser | undefined;
        querySnapshot.forEach((doc) => {
          foundUser = doc.data() as MyUser;
        });
        return foundUser;
      }
    } catch (error) {
      console.error("Fehler beim Überprüfen des Benutzers:", error);
    }
  },

  mapUser(user: User): MyUser {
    return {
      displayName: user.displayName,
      uid: user.uid,
      email: user.email,
      admin: true,
    };
  },

  saveUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem("user");
  },
};
